import React, { useState, useCallback  } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Gallery from '../components/Gallery';
//import logo from '../assets/images/main.jpg';
import Container from '../components/Container';
import MediaQuery from 'react-responsive';
import config from "../../config";

const ProyectosPage = ({ data }) => {
  //console.log('projectos', data.allProjectDataJson.edges);
  const projects = data.allProjectDataJson.edges; // data.allProjectDataJson.edges[0].node; //.album;
  const INTERIORISMO = projects.filter(p => p.node.category==="Interiorismo")
  const ARQUITTECTURA = projects.filter(p => p.node.category==="Arquitectura")

 //console.log(projects);
 const [mobileActive, setMobileActive] = useState('');

 const handleClick = useCallback((param) => {
  // handle the click event
  setMobileActive(param)
}, []);
  
 return (
    <Layout css={"bg-proyectos-img"}>
      <Container  >
        <MediaQuery maxDeviceWidth={ 991 }>
        {  config.proyectos.map( c  => {
          
           const {category} = c;
           const description = 'Ver más proyectos.'

          return (
            category === mobileActive ?
            <div id={category} onClick={e => handleClick('')} className="m">
              <Gallery
                projects={category ==="Interiorismo" ? INTERIORISMO : ARQUITTECTURA}
                title={category}
                description={description}
                width="68"
              />
            </div> 
          :

          <div id={category} onClick={e => handleClick(category)} className="e">
            <Gallery
              projects={category ==="Interiorismo" ? INTERIORISMO : ARQUITTECTURA}
              small={true}          
              title={category}
              description={description}
              width="80"
              />
          </div>
          )
        }
        )
       }
      </MediaQuery>

      <MediaQuery minDeviceWidth={1224} device={{ deviceWidth: 1600 }}>
          <div className="project-container">
            <Gallery
              projects={ARQUITTECTURA}
              title="Arquitectura"
              description=""
              width={80}
            />

            <Gallery
              projects={INTERIORISMO}
              title="Interiorismo"
              description=""
              width={80}
            />
          
          </div>
      </MediaQuery> 



    </Container>
  </Layout>
  );
};

export default ProyectosPage;

export const projectFragment = graphql`
  fragment projectFragment on ProjectDataJson {
    id
    seccionTitle
    description
    cover
    album
    category
  }
`;

export const projectsQuery = graphql`
  query {
    allProjectDataJson {
      edges {
        node {
          ...projectFragment
        }
      }
    }
  }
`;
